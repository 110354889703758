// extracted by mini-css-extract-plugin
export var actions = "_h-0";
export var actionsContainer = "_h-4";
export var border = "_h-1";
export var childFullHeight = "_h-b";
export var collapse = "_h-2";
export var content = "_h-6";
export var contentChild = "_h-a";
export var fullscreen = "_h-8";
export var hidePadding = "_h-7";
export var noScrollX = "_h-c";
export var noScrollY = "_h-d";
export var optOutTopPadding = "_h-9";
export var pagePadding = "_h-5";
export var profile = "_h-e";
export var profileContainer = "_h-f";
export var profileImage = "_h-g";
export var profileMain = "_h-h";
export var profileSubtitle = "_h-j";
export var profileTitle = "_h-i";
export var tabsWrapper = "_h-3";
export var title = "_h-k";