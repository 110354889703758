// extracted by mini-css-extract-plugin
export var active = "_24-a";
export var activeIndicator = "_24-9";
export var disabled = "_24-b";
export var fullWidth = "_24-2";
export var lg = "_24-7";
export var md = "_24-6";
export var redoButtonTabGroup = "_24-0";
export var sm = "_24-5";
export var tabButton = "_24-4";
export var tabButtonsContainer = "_24-1";
export var themeDestructive = "_24-d";
export var themeNormal = "_24-c";
export var themeSuccess = "_24-e";
export var xl = "_24-8";
export var xs = "_24-3";