// extracted by mini-css-extract-plugin
export var button = "_m-0";
export var buttonContent = "_m-a";
export var dontRestrainIconWidth = "_m-7";
export var dropdownButton = "_m-8";
export var iconContainer = "_m-6";
export var lg = "_m-4";
export var md = "_m-3";
export var pending = "_m-b";
export var sm = "_m-2";
export var spinner = "_m-9";
export var xl = "_m-5";
export var xs = "_m-1";