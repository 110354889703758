// extracted by mini-css-extract-plugin
export var border = "_1v-5";
export var button = "_1v-g";
export var center = "_1v-7";
export var centered = "_1v-3";
export var closeButton = "_1v-9";
export var disabledWrapper = "_1v-h";
export var error = "_1v-k";
export var fill = "_1v-d";
export var footer = "_1v-c";
export var footerAction = "_1v-e";
export var footerButtons = "_1v-f";
export var header = "_1v-4";
export var icon = "_1v-j";
export var info = "_1v-n";
export var inline = "_1v-6";
export var large = "_1v-2";
export var main = "_1v-a";
export var modal = "_1v-0";
export var neutral = "_1v-o";
export var none = "_1v-p";
export var scrollable = "_1v-b";
export var small = "_1v-1";
export var success = "_1v-m";
export var tight = "_1v-i";
export var titleIcon = "_1v-8";
export var warn = "_1v-l";