// extracted by mini-css-extract-plugin
export var arrows = "_1n-5";
export var decrementer = "_1n-9";
export var disabled = "_1n-3";
export var error = "_1n-1";
export var hiddenText = "_1n-a";
export var incrementer = "_1n-8";
export var input = "_1n-7";
export var inputWrapper = "_1n-0";
export var readonly = "_1n-2";
export var regular = "_1n-6";
export var small = "_1n-4";