// extracted by mini-css-extract-plugin
export var chip = "_1x-9";
export var chipLabel = "_1x-a";
export var close = "_1x-7";
export var closeIcon = "_1x-8";
export var error = "_1x-6";
export var infoTooltip = "_1x-2";
export var innerContainer = "_1x-1";
export var input = "_1x-b";
export var medium = "_1x-5";
export var outerContainer = "_1x-0";
export var small = "_1x-4";
export var xSmall = "_1x-3";