// extracted by mini-css-extract-plugin
export var accent = "_p-4";
export var add = "_p-j";
export var addIcon = "_p-k";
export var deleteIcon = "_p-i";
export var disabled = "_p-9";
export var ellipsis = "_p-f";
export var error = "_p-3";
export var form = "_p-5";
export var hideFocus = "_p-2";
export var icon = "_p-7";
export var input = "_p-e";
export var listItem = "_p-h";
export var mediumHeight = "_p-b";
export var noBorder = "_p-8";
export var noRadius = "_p-1";
export var shortHeight = "_p-a";
export var tallHeight = "_p-c";
export var textInput = "_p-0";
export var vertical = "_p-g";
export var veryTallHeight = "_p-d";
export var widget = "_p-6";