// extracted by mini-css-extract-plugin
export var container = "_10-0";
export var error = "_10-9";
export var label = "_10-5";
export var light = "_10-6";
export var md = "_10-3";
export var sm = "_10-2";
export var thin = "_10-8";
export var thinBold = "_10-7";
export var vertical = "_10-4";
export var xs = "_10-1";