// extracted by mini-css-extract-plugin
export var box = "_26-1";
export var checked = "_26-5";
export var descriptionSpacer = "_26-9";
export var disabled = "_26-4";
export var icon = "_26-6";
export var input = "_26-0";
export var labelSpacer = "_26-8";
export var medium = "_26-3";
export var small = "_26-2";
export var text = "_26-7";