// extracted by mini-css-extract-plugin
export var bubble = "_1d-0";
export var container = "_1d-3";
export var contrastBorder = "_1d-4";
export var greyOut = "_1d-2";
export var initialsWrapper = "_1d-1";
export var large = "_1d-9";
export var medium = "_1d-8";
export var mediumSmall = "_1d-7";
export var micro = "_1d-5";
export var small = "_1d-6";
export var tiny = "_1d-b";
export var xTiny = "_1d-a";