// extracted by mini-css-extract-plugin
export var disabled = "_l-6";
export var hierarchyLinkGray = "_l-a";
export var hierarchyLinkUnderline = "_l-9";
export var hierarchyPrimary = "_l-0";
export var hierarchySecondary = "_l-7";
export var hierarchyTertiary = "_l-8";
export var pressed = "_l-2";
export var themeAction = "_l-1";
export var themeDestructive = "_l-4";
export var themeNormal = "_l-3";
export var themeSuccess = "_l-5";