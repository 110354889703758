// extracted by mini-css-extract-plugin
export var addButton = "_1y-d";
export var bullets = "_1y-e";
export var compact = "_1y-2";
export var container = "_1y-j";
export var extendedWarrantyModal = "_1y-f";
export var learn = "_1y-l";
export var learnMore = "_1y-g";
export var offeringOption = "_1y-3";
export var offeringPrice = "_1y-8";
export var offeringSubtitle = "_1y-7";
export var offeringTitle = "_1y-6";
export var offeringsContainer = "_1y-0";
export var pdpTilesBackground = "_1y-n";
export var plan = "_1y-m";
export var regular = "_1y-1";
export var remove = "_1y-k";
export var seeDetailsIcon = "_1y-i";
export var seeDetailsLink = "_1y-h";
export var selectedOption = "_1y-4";
export var shieldIcon = "_1y-b";
export var shieldIconCompact = "_1y-c";
export var subtitle = "_1y-a";
export var title = "_1y-9";
export var unselectedOption = "_1y-5";