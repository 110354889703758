// extracted by mini-css-extract-plugin
export var box = "_21-0";
export var check = "_21-1";
export var checkboxButton = "_21-6";
export var checkboxGroup = "_21-5";
export var disabled = "_21-7";
export var ghost = "_21-9";
export var input = "_21-2";
export var label = "_21-4";
export var normal = "_21-8";
export var uncheck = "_21-3";