// extracted by mini-css-extract-plugin
export var grid = "_8-0";
export var span1 = "_8-2";
export var span10 = "_8-b";
export var span10L = "_8-z";
export var span10M = "_8-n";
export var span10Xl = "_8-1b";
export var span11 = "_8-c";
export var span11L = "_8-10";
export var span11M = "_8-o";
export var span11Xl = "_8-1c";
export var span12 = "_8-d";
export var span12L = "_8-11";
export var span12M = "_8-p";
export var span12Xl = "_8-1d";
export var span1L = "_8-q";
export var span1M = "_8-e";
export var span1Xl = "_8-12";
export var span2 = "_8-3";
export var span2L = "_8-r";
export var span2M = "_8-f";
export var span2Xl = "_8-13";
export var span3 = "_8-4";
export var span3L = "_8-s";
export var span3M = "_8-g";
export var span3Xl = "_8-14";
export var span4 = "_8-5";
export var span4L = "_8-t";
export var span4M = "_8-h";
export var span4Xl = "_8-15";
export var span5 = "_8-6";
export var span5L = "_8-u";
export var span5M = "_8-i";
export var span5Xl = "_8-16";
export var span6 = "_8-7";
export var span6L = "_8-v";
export var span6M = "_8-j";
export var span6Xl = "_8-17";
export var span7 = "_8-8";
export var span7L = "_8-w";
export var span7M = "_8-k";
export var span7Xl = "_8-18";
export var span8 = "_8-9";
export var span8L = "_8-x";
export var span8M = "_8-l";
export var span8Xl = "_8-19";
export var span9 = "_8-a";
export var span9L = "_8-y";
export var span9M = "_8-m";
export var span9Xl = "_8-1a";
export var stretch = "_8-1";