// extracted by mini-css-extract-plugin
export var alternate = "_1b-h";
export var black = "_1b-3";
export var blue = "_1b-d";
export var blueLight = "_1b-c";
export var brand = "_1b-7";
export var error = "_1b-9";
export var gray = "_1b-6";
export var grayBlue = "_1b-b";
export var iconWrapper = "_1b-4";
export var indigo = "_1b-e";
export var orange = "_1b-g";
export var pink = "_1b-f";
export var primary = "_1b-0";
export var purple = "_1b-8";
export var success = "_1b-5";
export var warning = "_1b-a";
export var white = "_1b-1";
export var whiteSuccess = "_1b-2";