// extracted by mini-css-extract-plugin
export var alignBaseline = "_4-8";
export var alignCenter = "_4-4";
export var alignFlexEnd = "_4-6";
export var alignFlexStart = "_4-5";
export var alignSelfBaseline = "_4-d";
export var alignSelfCenter = "_4-9";
export var alignSelfFlexEnd = "_4-b";
export var alignSelfFlexStart = "_4-a";
export var alignSelfStretch = "_4-c";
export var alignStretch = "_4-7";
export var column = "_4-3";
export var flex = "_4-0";
export var justifyCenter = "_4-e";
export var justifyFlexEnd = "_4-g";
export var justifyFlexStart = "_4-f";
export var justifySpaceAround = "_4-i";
export var justifySpaceBetween = "_4-h";
export var justifySpaceEvenly = "_4-j";
export var reverse = "_4-2";
export var row = "_4-1";
export var wrapNowrap = "_4-l";
export var wrapWrap = "_4-k";
export var wrapWrapReverse = "_4-m";