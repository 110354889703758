// extracted by mini-css-extract-plugin
export var brand = "_2d-7";
export var compact = "_2d-5";
export var extraSmall = "_2d-a";
export var icon = "_2d-0";
export var large = "_2d-3";
export var light = "_2d-1";
export var medium = "_2d-4";
export var outline = "_2d-9";
export var primaryDark = "_2d-8";
export var primaryLight = "_2d-6";
export var primaryModern = "_2d-b";
export var success = "_2d-2";