// extracted by mini-css-extract-plugin
export var background = "_2e-0";
export var container = "_2e-1";
export var contentItem = "_2e-7";
export var extendedWarranty = "_2e-5";
export var link = "_2e-d";
export var modal = "_2e-b";
export var modalCloseButton = "_2e-9";
export var modalContent = "_2e-3";
export var modalContentMain = "_2e-6";
export var modalImageLogo = "_2e-8";
export var modalLogo = "_2e-a";
export var modalSideImage = "_2e-4";
export var preview = "_2e-c";
export var redoModal = "_2e-2";