// extracted by mini-css-extract-plugin
export var background = "_2f-0";
export var buttonPrimary = "_2f-1";
export var container = "_2f-2";
export var feature = "_2f-5";
export var featureBody = "_2f-6";
export var featureIcon = "_2f-7";
export var featureSummary = "_2f-9";
export var featureTitle = "_2f-8";
export var modal = "_2f-f";
export var modalAction = "_2f-e";
export var modalCloseButton = "_2f-d";
export var modalFeatures = "_2f-4";
export var modalFooter = "_2f-a";
export var modalImageLogo = "_2f-b";
export var modalLogo = "_2f-c";
export var redoModal = "_2f-3";