// extracted by mini-css-extract-plugin
export var chevron = "_1s-b";
export var disabled = "_1s-a";
export var hideBorder = "_1s-1";
export var icon = "_1s-c";
export var input = "_1s-0";
export var open = "_1s-8";
export var placeholder = "_1s-9";
export var regular = "_1s-4";
export var singleSelectInput = "_1s-2 _1s-0";
export var small = "_1s-3";
export var spinnerContainer = "_1s-e";
export var tag = "_1s-6";
export var tagsContainer = "_1s-7";
export var tagsInput = "_1s-5 _1s-0";
export var xSmall = "_1s-d";