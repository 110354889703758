// extracted by mini-css-extract-plugin
export var checkbox = "_1g-0";
export var circle = "_1g-3";
export var horizontalContainer = "_1g-7";
export var sm = "_1g-5";
export var switch0 = "_1g-2";
export var switchDisabled = "_1g-8";
export var switchEnabled = "_1g-1";
export var textSwitchContainer = "_1g-9";
export var textSwitchOption = "_1g-a";
export var textSwitchOptionSelected = "_1g-b";
export var verticalContainer = "_1g-6";
export var xs = "_1g-4";